import request from '@/utils/request';

// 获取二维码
export function getQrcode() {
  return request.get('/wxScan_api/wxLogin');
}
// 获取登录状态
export function getLoginStatus(pLoginKey) {
  return request.post('/wxScan_api/getWxLoginInfo', {
    loginKey: pLoginKey,
  });
}
// 扫码登录
export function scanLogin(pToken) {
  request.defaults.headers.token = pToken;
  return request.post('/dengLuHm/gogogo');
}
// 账号密码登录
export function inputLogin(pObj) {
  return request.post('/dengLuHm/gogogo', {
    user_end_type: '1', // 西瓜互动
    phonenumber: pObj.phone,
    passwd: pObj.password,
    bindId: pObj.bindId,
    h5_openId: pObj.h5_openid,
  });
}

// 注册
export function register(pObj) {
  return request.post('/dengLuHm/zhuCeNe', {
    user_end_type: '1', // 西瓜互动
    phonenumber: pObj.phone,
    passwd: pObj.password,
    invite_key: pObj.inviteCode,
    code: pObj.valiCode,
    token: pObj.ext,
    h5_openId: pObj.openId || pObj.h5_openid,
    id: pObj.codeId,
    bindId: pObj.bindId,
    // h5_openid: pObj.h5_openid,
  });
}
// 发送验证码
export function sendCode(pObj) {
  return request.post('/dengLuHm/faSongCode', {
    user_end_type: '1',
    phonenumber: pObj.phone,
    source: pObj.source,
  });
}
// 修改密码
export function resetPassword(pObj) {
  return request.post('/dengLuHm/updateMiMa', {
    phonenumber: pObj.phone,
    codeId: pObj.codeId,
    passwd: pObj.password,
    token: pObj.ext,
    code: pObj.valiCode,
  });
}

// 修改密码
export function getList({
  showCount,
  currentPage,
}) {
  return request.post('/wxt/getSystemWxtInfoListPage', {
    currentPage,
    showCount,
  });
}

// 获取轮播
export function getCarouselList() {
  return request.post('/dengLuHm/getLbt', {
    sys_type: '2',
    bg_type: '6',
  });
}

// 提交需求
export function putRecord({
  phone,
  content,
  remark,

}) {
  return request.post('/cpInfo/saveCommentInfo', {
    phone_number: phone,
    content,
    op_remark: remark,
    type: '2',

  });
}
